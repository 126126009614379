import React from 'react';
import { Button, Form } from 'react-bootstrap';
import useSendPresentation from '../../hooks/useSendPresentation';
import useTheme from '../../hooks/useTheme';
import ElementAlert from '../ElementAlert/ElementAlert';

function PresentationSendForm({ selectedPresentation }) {
  const t = useTheme();
  const email = { name: 'email', label: 'E-mail cím', type: 'email', required: true };
  const initialValues = { email: '', selectedPresentation: selectedPresentation.slug.current, webform_id: 'send-presentation' };
  const { formik, isLoading, isSuccess, isError } = useSendPresentation({ initialValues });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Group key="email" controlId="email" className="send-presentation-form-group">
        <Form.Label>E-mail cím</Form.Label>
        <Form.Control placeholder="E-mail cím" value={formik.values[email.name]} onChange={formik.handleChange} />
      </Form.Group>
      <div className="d-flex justify-content-center subscribe-button-container">
        <Button variant="primary" type="submit" disabled={isLoading} className="subscribe-button">
          Küldés
          {isLoading && <span> ...</span>}
        </Button>
      </div>
      {isSuccess && (
        <ElementAlert
          variant="success"
          title={t.form.presentationConfirmation.successTitle}
          description={t.form.presentationConfirmation.successDescription}
          className="animate-up mt-6"
        />
      )}
      {isError && (
        <ElementAlert
          variant="danger"
          title={t.form.presentationConfirmation.errorTitle}
          description={t.form.presentationConfirmation.errorDescription}
          className="animate-up mt-6"
        />
      )}
    </Form>
  );
}

export default PresentationSendForm;
