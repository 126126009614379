import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ElementTags } from 'src/components/ElementTags/ElementTags';
import Download from 'src/svgs/download.svg';
import Presentation from 'src/svgs/presentation.svg';
import Share from 'src/svgs/share.svg';
import imgFallback from 'src/utils/image';
import './NodePresentationCard.scss';

function NodePresentationCard({ card, onRecommend }) {
  return (
    <Container fluid className="position-relative px-0">
      <Row noGutters className="animation-image-scale">
        <Col xs={12} className="presentation-image-holder mb-4">
          <GatsbyImage
            image={card.image?.image?.asset?.gatsbyImageData ?? imgFallback}
            alt={card.image?.alt ?? ''}
            className={`shadow-image d-flex presentation-image background--${card.category[0]?.color || ''}`}
          />
          <Presentation className="presentation-icon" />
        </Col>
        <Col xs={12} className="mb-2">
          <h3 className="h4 mb-0">{card.title}</h3>
        </Col>
        <Col xs={12} className="mb-3">
          <ElementTags category={card.category} />
        </Col>
        <Col xs={12} className="mb-5">
          <p className="text-muted mb-0">{card.description}</p>
        </Col>
        <Col xs={12}>
          <div className="presentation-buttons">
            <button type="button" className="presentation-share-button" onClick={() => onRecommend(card)}>
              <Share />
              <span>Ajánlom</span>
            </button>
            <a className="presentation-download-button" href={card.presentation.asset.url} download={card.presentation.asset.name}>
              <Download />
              Letöltöm
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

NodePresentationCard.propTypes = {
  card: PropTypes.shape({}).isRequired,
};

export default NodePresentationCard;
