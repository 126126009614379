import { graphql } from 'gatsby';
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import FlipMove from 'react-flip-move';
import { ElementTag } from '../components/ElementTags/ElementTags';
import NodePresentationCard from 'src/components/NodePresentationCard/NodePresentationCard';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';
import PresentationSendForm from 'src/components/PresentationSendForm/PresentationSendForm';
import Hero from 'src/components/PresentationsPage/Hero/Hero';
import WebFormContext from 'src/components/WebFormContext/WebFormContext';

function getCategoriesReferencedByPresentations(presentations) {
  const categories = presentations.reduce((acc, curr) => [...acc, ...curr.category], []);

  return uniqBy(categories, 'id');
}

function PresentationsPage({ data, location }) {
  const {
    sanityBasicPage: {
      presentations: { hero },
      title,
      pageSeo,
    },
    allSanityPresentation: { nodes: presentations },
  } = data;

  const seo = {
    title: pageSeo?.title ?? title,
    description: pageSeo?.description,
    image: pageSeo?.image?.asset?.url,
    location,
  };

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedPresentation, setSelectedPresentation] = useState(null);

  const hideModal = (e) => {
    if (e.target.className === 'presentation-modal-container') {
      setShowModal(false);
    }
  };

  const openModal = (presentation) => {
    setSelectedPresentation(presentation);
    setShowModal(true);
  };

  function handleClick(e, id) {
    e.preventDefault();
    setSelectedCategoryId(id);
  }

  const categories = [
    {
      name: 'Mind',
      color: 'primary',
      id: null,
    },
    ...getCategoriesReferencedByPresentations(presentations),
  ];

  function getFilteredPresentations(selectedCatId) {
    if (!selectedCatId) {
      const customSortOrder = [
        'Kiterjesztett valóság (AR I MR)', // 1st priority
        'iOS és Android applikációk', // 2nd priority
        'Unity alapú multiplatform applikációk', // 3rd priority
        'Érintőképernyős megoldások',
      ];

      const sortedPresentations = presentations.sort((a, b) => {
        const categoryA = a.category[0].name;
        const categoryB = b.category[0].name;

        return customSortOrder.indexOf(categoryA) - customSortOrder.indexOf(categoryB);
      });
      return sortedPresentations;
    }

    const filteredPresentations = presentations.filter((presentation) => {
      const categoriesOfThePresentation = presentation.category;
      return categoriesOfThePresentation.filter((category) => category.id === selectedCatId).length > 0;
    });
    return filteredPresentations;
  }

  return (
    <PageLayout layoutClassName="presentation-layout">
      <div className="presentation-banner">Ajánld ismerősödnek a prezentációmat!</div>
      <WebFormContext>
        <PageSEO {...seo} />
        <Container fluid className="layy bg-shapes-banner px-0">
          <Hero hero={hero} />
        </Container>
        <Container className="presentations-container">
          <div className="presentations-tagline">TÖBBET SZERETNE TUDNI?</div>
          <div className="presentations-title">
            Tekintse át, használja fel, küldje tovább
            <br /> prezentációinkat!
          </div>
          <Row noGutters className="mx-n2 presentations-categories">
            {categories.map((category) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Col key={category.id} xs="auto" className="px-2">
                <ElementTag
                  onClick={(e) => handleClick(e, category.id)}
                  isActive={selectedCategoryId === category.id}
                  color={category.color}
                  id={category.id}
                  name={category.name}
                  isButton
                />
              </Col>
            ))}
          </Row>
          <Row style={{ position: 'relative' }} className="mx-xl-n3">
            <FlipMove typeName={null}>
              {getFilteredPresentations(selectedCategoryId).map((presentation) => (
                <Col key={presentation.id} xs={12} lg={4} className="px-xl-3 py-5">
                  <NodePresentationCard card={presentation} onRecommend={openModal} />
                </Col>
              ))}
            </FlipMove>
          </Row>
        </Container>
        {showModal ? (
          /* eslint-disable jsx-a11y/click-events-have-key-events */
          /* eslint-disable jsx-a11y/no-static-element-interactions */
          <div className="presentation-modal-container" onClick={hideModal}>
            <div className="presentation-modal">
              <div className="presentation-modal__tagline">AJÁNLÁS</div>
              <div className="presentation-modal__title">
                Küldje el “{selectedPresentation.name}” című prezentációnkat az Ön által megadott e-mail címre!
              </div>
              <div className="presentation-modal__body">
                <PresentationSendForm selectedPresentation={selectedPresentation} />
              </div>
            </div>
          </div>
        ) : null}
      </WebFormContext>
    </PageLayout>
  );
}

export const query = graphql`
  query ($id: String!) {
    sanityBasicPage(id: { eq: $id }) {
      title
      pageSeo {
        ...getPageSeo
      }
      presentations {
        hero {
          ...getHero
        }
      }
    }

    allSanityPresentation(sort: { order: DESC, fields: _createdAt }) {
      nodes {
        ...getPresentationAsCard
      }
    }
  }
`;

PresentationsPage.propTypes = {
  allSanityPresentation: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
};
export default PresentationsPage;
