import { PortableText } from '@portabletext/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Hero.scss';

function Hero({ hero: { title, description } }) {
  return (
    <Container className="presentation-hero">
      <Row>
        <Col xs={12} md={8} lg={6} xl={{ span: 5, offset: 1 }} className="d-flex flex-column justify-content-start">
          <h2 className="hero-top-text">Tetszett az előadásunk?</h2>
          <h1 className="h2 hero-title px-0">{title}</h1>
        </Col>
        <Col xs={12} md={8} lg={6} xl={5} className="mt-lg-0 d-flex flex-column justify-content-start">
          {description && (
            <div className="text-muted hero-description">
              <PortableText value={description} />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

Hero.propTypes = {
  hero: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default Hero;
